import React from 'react';
import './App.css';

function App() {
    return (
        <div className="App">
            <div className="TitleContainer">
                <div className="Title">
                    GoRead
                </div>
            </div>

            <div className="SubtitleContainer">
                <div className="Subtitle">
                    GoRead is a company based in Stockholm, Sweden.
                </div>
            </div>

            <div className="LowerContainer">
                <div className="EmailContainer">
                    <div className="Email">
                        info@goread.com
                    </div>
                </div>

                <div className="CopyrightContainer">
                    <div className="Copyright">
                        © 2023 GoRead 559375-4426
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
