import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppleAppSiteAssociation from './.well-known/apple-app-site-association.json';

const reload = () => window.location.reload();


export default function App2() {
    // @ts-ignore
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route
                        path="hej"
                        // @ts-ignore
                        render={() => (
                            <div>
                                <p>HEJ</p>
                                {/*<pre>{JSON.stringify(AppleAppSiteAssociation, null, 2)}</pre>*/}
                            </div>
                        )}
                    />
                </Route>

                {/*<Route*/}
                {/*    path=".well-known/apple-app-site-association"*/}
                {/*    element={<div>*/}
                {/*        <pre>{JSON.stringify(AppleAppSiteAssociation, null, 2)}</pre>*/}
                {/*    </div>}*/}
                {/*/>*/}
            </Routes>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App2 />);

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
